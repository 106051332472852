import request from '@/plugin/axios'

export function fetchList (query) {
  return request({
    url: '/tms/tmshy/page',
    method: 'post',
    data: query
  })
}

export function addObj (obj) {
  return request({
    url: '/tms/tmshy',
    method: 'post',
    data: obj
  })
}

export function getObj (id) {
  return request({
    url: '/tms/tmshy/' + id,
    method: 'get'
  })
}

export function delObj (id) {
  return request({
    url: '/tms/tmshy/' + id,
    method: 'delete'
  })
}

export function putObj (obj) {
  return request({
    url: '/tms/tmshy',
    method: 'put',
    data: obj
  })
}
/**
 * 货源单 新增保存
 */
export function hysaveDD (obj) {
  return request({
    url: '/tms/tmshy/hyadd',
    method: 'post',
    data: obj
  })
}
/**
 * 货源单 通过ids批量删除货源
 */
export function hydelObjs (ids) {
  return request({
    url: '/tms/tmshy/delids',
    method: 'post',
    params: ids
  })
}
/**
 * 编辑 取得货源信息
 */
export function hygetDetails (id) {
  return request({
    url: '/tms/tmshy/hydetails/' + id,
    method: 'post'
  })
}
/**
 * 编辑 更新货源主表
 */
export function hyupdateDD (obj) {
  return request({
    url: '/tms/tmshy/hyupdate',
    method: 'post',
    data: obj
  })
}

/**
 * 详情装车明细
 */
export function hydetails (hyid) {
  return request({
    url: '/tms/tmshydetails/hydetails/' + hyid,
    method: 'get'
  })
}

/**
 * 取得预订单ids
 */
export function getDdis (hyids) {
  return request({
    url: '/tms/tmshy/getDdids',
    method: 'post',
    params: { ids: hyids }
  })
}

/**
 * 取得货源取得短驳ids
 */
export function getDtids (hyids) {
  return request({
    url: '/tms/tmshy/getDtids',
    method: 'post',
    params: { ids: hyids }
  })
}

/**
 * 发布状态
 */
export function publicState (id) {
  return request({
    url: '/tms/tmshy/publicstate/' + id,
    method: 'get'
  })
}
/**
 * 取得货源取得中转ids
 */
export function getTyids (hyids) {
  return request({
    url: '/tms/tmshy/getTyids',
    method: 'post',
    params: { ids: hyids }
  })
}
/**
 * 司机认证 获取司机信息
 */
export function getDriverMsg (obj) {
  return request({
    url: '/tms/auth/driver/details',
    method: 'get',
    params: { hyNo: obj }
  })
}
/**
 * 司机认证 认证
 */
export function carAuthApprove (obj) {
  return request({
    url: '/basic/auth/save',
    method: 'post',
    data: obj
  })
}
export function uploadImage (obj) {
  return request({
    url: '/tms/auth/uploadImage',
    method: 'post',
    params: obj
  })
}
/**
 * 司机认证 司机合同信息完善后才能打开车辆认证功能
 */
export function getDriverContract (obj) {
  return request({
    url: '/tms/tmsbj/contract',
    method: 'get',
    params: { hyNo: obj }
  })
}

export function supplyYmm (id) {
  return request({
    url: '/tms/tmshy/publiccmm/' + id,
    method: 'get'
  })
}
/**
 * 取得货源信息
 */
export function hygetHyNo (hyNo) {
  return request({
    url: '/tms/tmshy/hyNo',
    method: 'post',
    params: { hyNo: hyNo }
  })
}
/**
 * 上传阿里 托运单保存到后台
 */
export function uploadAliHy (obj) {
  return request({
    url: '/tms/ossobject/supply',
    method: 'post',
    data: obj
  })
}
/**
 * 货源 交易历史 查询
 */
export function searchHyHistory (query) {
  return request({
    url: '/tms/tmshy/historyPrice',
    method: 'post',
    data: query
  })
}
export function getAveragePrice (query) {
  return request({
    url: '/tms/tmshy/averagePrice',
    method: 'post',
    params: query
  })
}
/**
 * 导出报价*
 */
export function exportBj (obj) {
  return request({
    url: '/tms/tmshy/sourceQuotationDetails',
    method: 'post',
    responseType: 'blob',
    data: obj
  })
}
/**
 * 通过ids短信模板预览界面接口
 */
export function getMessage (obj) {
  return request({
    url: '/tms/tmshy/sendSmsFunction',
    method: 'post',
    params: obj
  })
}
/**
 * 货源单 通过ids批量发送短信以及小程序消息推送
 */
export function batchSMS (ids) {
  return request({
    url: '/tms/tmshy/batchSendInformation',
    method: 'post',
    params: ids
  })
}
/**
 * 货源历史 通过ids批量发送短信
 */
export function getBatchMessage (ids) {
  return request({
    url: '/tms/tmshy/massProductionSmsFunction',
    method: 'post',
    params: ids
  })
}
/**
 * 货源历史 通过hyNo批量发送短信
 */
export function getBatchLocation (hyNo) {
  return request({
    url: '/tms/gps/queryBatchLocation',
    method: 'post',
    params: hyNo
  })
}
export function pushHYB (id) {
  return request({
    url: '/tms/tmshy/push/hyb/' + id,
    method: 'get'
  })
}
export function batchPushHYB (ids) {
  return request({
    url: '/tms/tmshy/push/hyb',
    method: 'post',
    params: ids
  })
}
export function intellectBargaining (obj) {
  return request({
    url: '/tms/tmshy/intellectBargaining',
    method: 'post',
    params: obj
  })
}
