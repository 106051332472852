<template>
  <el-dialog
    :title="state === 'add'?'新增':state === 'edit'?'修改': state === 'editBasic'? '修改基础信息' : state === 'return'? '物品归还' : state === 'course' ? '修改进程状态': '新增（复制）'"
    :visible.sync="DailogShow"
    class="DialogTD"
    style="height: 90%;"
    :width="editWidth"
    :close-on-click-modal="false"
    :before-close="handleClose">
    <el-card shadow="always">
      <form-create class="td_form_edit" v-if="isShow" :rule="rule" ref="fc" :option="options"/>
    </el-card>
  </el-dialog>
</template>

<script>
import { getEditUser, validateUrl } from '@/api/sys.ui'
import { validatenull } from '@/tdcommon/validate'
import { getdict } from '@api/admin/dict'
import { getBranchUser } from '@/api/admin/user'
import { downValues } from '@/tdcommon/beeutil'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'd2-container-edit',
  props: {
    ModularName: {
      type: String,
      default: () => ''
    },
    isDailogShow: {
      type: Boolean,
      default: () => false
    },
    state: {
      type: String,
      default: () => 'add'
    },
    editFrom: {
      type: Object,
      default: () => {}
    },
    editWidth: {
      type: String,
      default: () => '40%'
    }
  },
  data () {
    return {
      addForm: {},
      DailogShow: this.isDailogShow,
      isShow: true,
      // 使用人选择时,带出相应的输入框的内容
      publicCus: [],
      // 表单生成规则
      rule: [],
      options: {
        // 取消按钮
        resetBtn: {
          // 类型 primary / success / warning / danger / info / text
          type: 'danger',
          // 尺寸 medium / small / mini
          size: 'small',
          // 是否朴素按钮
          plain: false,
          // 是否圆角按钮
          round: false,
          // 是否圆形按钮
          circle: false,
          // 是否加载中状态
          loading: false,
          // 是否禁用状态
          disabled: false,
          // 图标类名
          icon: '',
          // 是否默认聚焦
          autofocus: false,
          // 原生 type 属性
          nativeType: 'button',
          // 按钮内容
          innerText: '取消',
          // 按钮是否显示
          show: true,
          // 按钮布局规则
          col: { span: 3, push: 14 },
          // 按钮点击事件
          click: this.outSubmit
        },
        // 新增按钮
        submitBtn: {
          // 类型 primary / success / warning / danger / info / text
          type: 'primary',
          // 尺寸 medium / small / mini
          size: 'small',
          // 是否朴素按钮
          plain: false,
          // 是否圆角按钮
          round: false,
          // 是否圆形按钮
          circle: false,
          // 是否加载中状态
          loading: false,
          // 是否禁用状态
          disabled: false,
          // 图标类名
          icon: '',
          // 是否默认聚焦
          autofocus: false,
          // 原生 type 属性
          nativeType: 'button',
          // 按钮内容
          innerText: '保存',
          // 按钮是否显示
          show: true,
          // 按钮布局规则
          col: { span: 3, push: 21 },
          // 按钮点击事件
          click: this.onSubmit
        },
        form: {
          size: 'mini'
        }
      }
    }
  },
  mounted () {
    this.getEditUI()
  },
  created () {
  },
  computed: {
    ...mapGetters(['permissions']),
    ...mapState('d2admin/user', [
      'info'
    ])
  },
  methods: {
    init (type, index) {
      // 如果dict_type = 'userMarkList'
      if (type === 'userMarkList') {
        // 获取 使用人、查看人 下拉加载项
        getBranchUser(this.info.username).then(response => {
          this.rule[index].options = downValues(response.data)
        })
      } else {
        // 获取 字典项 下拉加载项
        getdict(type).then(response => {
          let listItem = []
          response.data.forEach(item => {
            let option = {}
            option.label = item.label
            option.value = item.value
            listItem.push(option)
          })
          this.rule[index].options = listItem
        }).catch(() => {
        })
      }
    },
    // 取得查询条件界面参数
    getEditUI () {
      this.isShow = false
      getEditUser(this.ModularName).then(response => {
        this.setEditUI(response.data.editColumns)
        this.isShow = true
      }).catch(() => {
        this.isShow = true
      })
    },
    // 处理界面信息
    setEditUI (editList) {
      editList.forEach((item, index) => {
        var uis = {}
        if (item.editIsAddshow === '1') {
          uis.type = 'hidden'
        } else {
          uis.type = item.editType
        }
        uis.title = item.label
        uis.field = item.prop
        uis.on = this.emitOn(item.prop)
        uis.value = item.editDeafulValue
        uis.col = validatenull(item.editCol) ? [] : JSON.parse(item.editCol)
        uis.props = validatenull(item.editProps) ? [] : JSON.parse(item.editProps)
        // uis.props = this.emitSearch(item.prop, validatenull(item.editProps) ? [] : JSON.parse(item.editProps))
        // eslint-disable-next-line no-eval
        uis.validate = validatenull(item.editValidate) ? [] : eval(item.editValidate)
        // 判断isOnly 和 onlyUrl 表单验证 判断是否名称重复
        if (item.isOnly === '0' && item.onlyUrl !== undefined && item.onlyUrl !== '') {
          uis.validate.push(this.creatValidate(item.onlyUrl, item.label))
        }
        // eslint-disable-next-line no-eval
        uis.options = validatenull(item.editOptions) ? [] : eval(item.editOptions)
        // eslint-disable-next-line no-eval
        uis.emit = validatenull(item.editEmit) ? [] : eval(item.editEmit)
        this.addForm[item.prop] = item.editDeafulValue
        this.rule.push(uis)
        if (item.dictType !== null && item.dictType.length !== 0 && validatenull(item.editOptions)) {
          this.init(item.dictType, index)
        } else if (item.dictUrl !== null && item.dictUrl.length !== 0 && validatenull(item.editOptions)) {
          this.init(item.dictType, index)
        }
      })
    },
    // 关闭时动作处理
    handleClose (done) {
      this.$emit('editHandleClose', false)
      // this.$confirm('确认关闭？').then(() => {
      //   this.$emit('editHandleClose', false)
      //   done()
      // }).catch(() => {
      // })
    },
    onSubmit () {
      // 表单验证
      this.$refs.fc.$f.validate((valid) => {
        if (valid) {
          this.$emit('editHandleSub', this.$refs.fc.$f.formData())
        }
      })
    },
    // 取消按钮
    outSubmit () {
      this.handleClose()
    },
    setEditValue (obj) {
      this.rule.forEach((item, index) => {
        this.rule[index].value = obj[item.field]
      })
    },
    // 验证 判断是否重复存在
    creatValidate (url, title) {
      return { validator: (rule, value, callback, source, options) => this.validateMethods(rule, value, callback, source, options, url, title), trigger: 'blur' }
    },
    validateMethods (rule, value, callback, source, options, url, title) {
      if (!validatenull(value)) {
        validateUrl(url, value).then(response => {
          if (this.state === 'edit') callback()
          const result = response.data
          if (!validatenull(result)) { // 如果不等于空
            callback(new Error(title + '已经存在'))
          } else {
            callback()
          }
        })
      }
    },
    setAddValue () {
      this.rule.forEach((item, index) => {
        this.rule[index].value = this.addForm[item.field]
      })
    },
    loadOptions (prop, options) {
      this.rule.forEach((item, index) => {
        if (this.rule[index].field === prop) {
          this.rule[index].options = options
        }
      })
    },
    emitOn (props) {
      let on = {}
      on.change = (val) => this.emitchange(val, props)
      return on
    },
    // 使用人、查看人 公用方法
    emitchange (val, props) {
      this.$emit('on' + props, val)
    }
  },
  watch: {
    isDailogShow (newVal) {
      this.DailogShow = newVal
    },
    DailogShow (newVal) {
      this.$emit('input', newVal)
    }
  }
}
</script>

<style lang="scss">
  .td_form_edit .el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 100%
  }

  .td_form_edit .el-select--default {
    width: 100%
  }

  .td_form_edit .el-input-number {
    width: 100%
  }
  /*el-dialog边框阴影*/
  .DialogTD .el-dialog {
    //border-radius: 10px;
    //border: 1px solid #909399;
    //box-shadow: 0 0 9px 3px #C0C4CC;
  }
  /*el-dialog头部背景色*/
  .DialogTD .el-dialog__header {
    //background-color: #409eff;
    //border-radius: 5px 5px 0 0;
    border-bottom: 1px solid #F0F0F0;
  }
  /*el-dialog头部文字和X的颜色*/
  .DialogTD .el-dialog__title {
    //color: #FFFFFF;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 600;
    color: #343434;
    //line-height: 36px;
    letter-spacing: 1px;
  }
  //.DialogTD .el-icon-close:before {
  //
  //}
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-edit/index.vue"
}
</vue-filename-injector>
