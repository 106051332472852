import request from '@/plugin/axios'

export function fetchTree (query) {
  return request({
    url: '/admin/dept/tree',
    method: 'get',
    params: query
  })
}

export function addObj (obj) {
  return request({
    url: '/admin/dept/',
    method: 'post',
    data: obj
  })
}

export function getObj (id) {
  return request({
    url: '/admin/dept/' + id,
    method: 'get'
  })
}

export function delObj (id) {
  return request({
    url: '/admin/dept/' + id,
    method: 'delete'
  })
}

export function dingSync () {
  return request({
    url: '/admin/dept/deptSyc',
    method: 'get'
  })
}

export function putObj (obj) {
  return request({
    url: '/admin/dept/',
    method: 'put',
    data: obj
  })
}
/*
*部门名称 判断重复 *
*/
export function getDetails (obj) {
  return request({
    url: '/admin/dept/details/' + obj,
    method: 'get'
  })
}
/*
*获取只有业务(项目)部门 *
*/
export function getOperationDept (query) {
  return request({
    url: '/admin/dept/queryDeptsList',
    method: 'get',
    params: query
  })
}
/*
*获取只有调度的部门 *
*/
export function getDispatchDept (query) {
  return request({
    url: '/admin/dept/queryDeptList',
    method: 'get',
    params: query
  })
}
