import store from '@/store'
import axios from 'axios'
import { Message } from 'element-ui'
import util from '@/libs/util'
import { serialize } from '@/tdcommon/beeutil'

/**
 *腾大API
 * @param msg
 */
// 创建一个错误
function errorCreate (msg) {
  const error = new Error(msg)
  errorLog(error)
  throw error
}

// 记录和显示错误
function errorLog (error) {
  // 添加到日志
  store.dispatch('d2admin/log/push', {
    message: '数据请求异常',
    type: 'danger',
    meta: {
      error
    }
  })
  // 打印到控制台
  if (process.env.NODE_ENV === 'development') {
    util.log.danger('>>>>>> Error >>>>>>')
    console.log(error)
  }
  // 显示提示
  Message({
    message: error.message,
    type: 'error',
    duration: 5 * 1000
  })
}

// 创建一个 axios 实例
const service = axios.create({
  baseURL: process.env.VUE_APP_API,
  timeout: 120000 // 请求超时时间
})

// 请求拦截器
service.interceptors.request.use(
  config => {
    // headers中配置serialize为true开启序列化
    if (config.method === 'post' && config.headers.serialize) {
      config.data = serialize(config.data)
      delete config.data.serialize
    }
    return config
  },
  error => {
    // 发送失败
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  response => {
    // dataAxios 是 axios 返回数据中的 data
    const dataAxios = response.data
    // 这个状态码是和后端约定的
    const code = Number(response.status) || 200
    // 有 code 代表这是一个后端接口 可以进行进一步的判断
    switch (code) {
      case 200:
        // [ 示例 ] code === 0 代表没有错误
        return dataAxios
      case 401:
        return this.router.push('/login')
      default:
        // 不是正确的 code
        errorCreate(`${dataAxios.msg}: ${response.config.url}`)
        break
    }
  },
  error => {
    if (error && error.response) {
      switch (error.response.status) {
        case 400: error.message = '请求错误'; break
        case 401: error.message = '当前操作没有权限'; break
        case 403: error.message = '当前操作没有权限'; break
        case 404: error.message = `资源不存在: ${error.response.config.url}`; break
        case 408: error.message = '请求超时'; break
        case 417: error.message = '未绑定登录账号，请使用密码登录后绑定'; break
        case 426: error.message = '用户名不存在或密码错误'; break
        case 428: error.message = '验证码错误,请重新输入'; break
        case 429: break
        case 500: error.message = '服务器内部错误'; break
        case 501: error.message = '服务未实现'; break
        case 502: error.message = '网关错误'; break
        case 503: error.message = '服务不可用'; break
        case 504: error.message = '网关超时'; break
        case 505: error.message = 'HTTP版本不受支持'; break
        case 506: error.message = '密码已过期,请重置密码'; break
        default: break
      }
    }
    errorLog(error)
    return Promise.reject(error)
  }
)

export default service
