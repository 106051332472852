<template>
  <div style="width: 100%;height: 100%;" @contextmenu.stop.prevent="(event) => showMenu(event, params)">
    <template v-if="params.tableIsSlot === '0' && !params.node.rowPinned">
      <slot :name="params.prop" :row="params.data" :dicData="params.editOptions"></slot>
    </template>
    <template v-else-if="params.dictType !== '' && params.dictUrl === '' && params.editOptions === null && !params.node.rowPinned">
      <template v-for="(items, index) in dictTypeOptions">
        <el-tag v-if="items.value === params.value" :key="params.value + items.value + index"
                :type="items.type" :effect="items.effect"
                :size="items.size" :color="items.color"
                :style="items.style" disable-transitions>
          {{items.label}}
        </el-tag>
      </template>
    </template>
    <template v-else-if="params.dictUrl !== '' && params.dictType === '' && params.editOptions === null && !params.node.rowPinned">
      <template v-for="(items, index) in dictUrlOptions">
        <el-tag v-if="items.value === params.value" :key="params.value + items.value + index"
                :type="items.type" :effect="items.effect"
                :size="items.size" :color="items.color"
                :style="items.style" disable-transitions>
          {{items.label}}
        </el-tag>
      </template>
    </template>
    <template v-else-if="params.tableType === 'tag' && !params.node.rowPinned">
      <template v-for="(items, index) in (typeof params.editOptions === 'string' ? JSON.parse(params.editOptions) : params.editOptions)">
        <el-tag v-if="items.value === params.value" :key="params.value + items.value + index"
                :type="items.type" :effect="items.effect"
                :size="items.size" :color="items.color"
                :style="items.style" disable-transitions>
          {{items.label}}
        </el-tag>
      </template>
    </template>
    <template v-else-if="params.tableType === 'openDetails' && !params.node.rowPinned">
      <el-link type="primary" :readonly="true" :underline="false" style="font-size:13px; padding: 0;" @click="openDetails(params.data)">{{params.value}}</el-link>
    </template>
    <template v-else-if="params.tableType ==='date' && !params.node.rowPinned">
      <span>{{formDate('YYYY-MM-DD',params.value)}}</span>
    </template>
    <template v-else-if="params.tableType ==='datetime' && !params.node.rowPinned">
      <span>{{formDate('YYYY-MM-DD HH:mm:ss',params.value)}}</span>
    </template>
    <template v-else>
      <span>{{params.value}}</span>
    </template>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import { validatenull } from '@/tdcommon/validate'
import { getdict } from '@/api/admin/dict'
import { getDictUrl } from '@/api/sys.ui'

export default {
  data () {
    return {
      dictTypeOptions: [],
      dictUrlOptions: []
    }
  },
  created () {
    if (this.params.tableIsSlot === '0') {
      this.$slots = this.params.slots
      this.$scopedSlots = this.params.scopedSlots
    }
  },
  mounted () {
    if (!validatenull(this.params.dictType) && validatenull(this.params.dictUrl) && validatenull(this.params.editOptions)) {
      getdict(this.params.dictType).then(response => {
        this.dictTypeOptions = response.data
      })
    }
    if (!validatenull(this.params.dictUrl) && validatenull(this.params.dictType) && validatenull(this.params.editOptions)) {
      getDictUrl(this.params.dictUrl).then(response => {
        this.dictUrlOptions = response.data
      })
    }
  },
  methods: {
    /**
     * 日期格式处理
     */
    formDate (formType, Dates) {
      return dayjs(Dates).format(formType)
    },
    // a标签点击
    openDetails (row) {
      this.params.openDetails(row)
    },
    // 上下菜单功能
    showMenu (event, data) {
      this.params.showMenu(event, data)
    },
    // tag处理
    setTag (options) {
      options.forEach(items => {
        items.type = validatenull(items.type) ? '' : items.type
        items.size = validatenull(items.size) ? 'mini' : items.size
        items.effect = validatenull(items.effect) ? 'light' : items.effect
        items.color = validatenull(items.color) ? '' : items.color
        items.style = validatenull(items.color) ? '' : items.color
      })
      return options
    }
  }
}
</script>

<style scoped>

</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-data/cellRenderer.vue"
}
</vue-filename-injector>
